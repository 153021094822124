import React, { useEffect, useState } from "react";
import "./index.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";

import IconeMaoCoracao from "../../assets/images/png/iconeMaoCoracao.png";
import Emogis from "../../assets/images/png/emogis.png";
import Joia from "../../assets/images/png/joia.png";
import CarinhaFeliz from "../../assets/images/png/carinhaFeliz.png";
import MaoDandoEstrelas from "../../assets/images/png/maoDandoEstrelas.png";
import imagemPlanos from "../../assets/images/png/Banner1.png";
import { Divider } from "@mui/material";
import Cards from "./CardPlanos";

export default function Home() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <img width={"100%"} src={imagemPlanos} alt="" />

      <div className="d-flex justify-content-center w-100">
        <Cards />
      </div>
    </>
  );
}
