import React from "react";
import "./index.css";
import Icons from "../../assets/images/png/icons.png";
import SeloIbama from "../../assets/images/png/selo_ibama.png";
import logoChevrolet from "../../assets/images/png/logo_chevrolet.png";
import logoMetilife from "../../assets/images/png/logoMetilife.png";
import { Divider } from "@mui/material";

export default function Footer() {
  return (
    <>
      <footer className="footer border-top pt-5 pb-3">
        <div className="container">
          <div className="row">
            <img
              src={logoChevrolet}
              alt="iconesSites"
              style={{ maxWidth: 200 }}
              className="mb-3 me-4"
            />
            <img
              src={logoMetilife}
              alt="iconesSites"
              style={{ maxWidth: 200 }}
              className="mb-3"
            />
          </div>
          <div className="row ">
            <div className="mt-2 d-flex ">
              <img
                src={SeloIbama}
                alt="seloIbama"
                className="img-fluid mb-4 imgIbama"
              />
              <div className="mt-2 ms-1" style={{ fontSize: 10 }}>
                JUNTOS SALVAMOS VIDAS.
              </div>

              <p className="textoFooter ms-2">
                As disposições aqui referidas são uma breve descrição do seguro.
                Restrições se aplicam. Produto registrado na SUSEP sob nº
                15414.600674/2022-38 sob responsabilidade da Metropolitan Life
                Seguros e Previdência Privada S.A., código SUSEP 0635-1, CNPJ
                02.102.498/0001-29. Para conhecer na íntegra as condições do
                produto, consulte as Condições Gerais do Produto disponível no
                site{" "}
                <a
                  className="linkFooter"
                  href="https://www.metlife.com.br"
                  target="_blank"
                >
                  www.metlife.com.br
                </a>
                . A aceitação da proposta está sujeita à análise do risco.
                <br />O registro do produto é automático e não representa
                aprovação ou recomendação por parte da Susep. O segurado poderá
                consultar a situação cadastral do corretor de seguros e da
                sociedade seguradora no sítio eletrônico{" "}
                <a
                  className="linkFooter"
                  href="https://www.susep.gov.br"
                  target="_blank"
                >
                  www.susep.gov.br
                </a>
                . Se o Segurado, seu representante legal, ou seu corretor de
                seguros fizer declarações inexatas ou omitir circunstâncias que
                possam influir na aceitação da Proposta ou no valor do Prêmio, a
                Seguradora não pagará qualquer Indenização com base no presente
                seguro, além de estar o Segurado obrigado ao pagamento do prêmio
                vencido. As condições contratuais deste produto encontram-se
                registradas na SUSEP e poderão ser consultadas no endereço
                eletrônico{" "}
                <a
                  className="linkFooter"
                  href="https://www.susep.gov.br"
                  target="_blank"
                >
                  www.susep.gov.br
                </a>
                , de acordo com o número de processo SUSEP. Para outras
                informações DISQUE SUSEP 0800-021-8484 de 2ª a 6ª das 9:30h as
                17h.SAC. Este seguro é por prazo determinado tendo a seguradora
                a faculdade de não renovar a apólice na data de vencimento, sem
                devolução dos prêmios pagos nos termos da apólice, de acordo com
                o número de processo constante da apólice/proposta. O segurado
                poderá desistir do seguro contratado no prazo de 7 (sete) dias
                corridos a contar da formalização da proposta e poderá exercer
                seu direito de arrependimento pelo mesmo meio utilizado para
                contratação, sem prejuízo de utilizar outros meios
                disponibilizados pela Seguradora. As Condições Gerais completas
                estão disponíveis para consulta no site{" "}
                <a
                  className="linkFooter"
                  href="https://www.metlife.com.br"
                  target="_blank"
                >
                  www.metlife.com.br
                </a>
                . Central de Atendimento: 3003 Life - 3003 5433 (capitais e
                grandes centros), 0800 MetLife - 0800 638 5433 (demais
                localidades). SAC - Serviço de Apoio ao Cliente Reclamações,
                Cancelamentos, Sugestões e Elogios: 0800 746 3420 (24 horas por
                dia, 7 dias por semana, em todo o Brasil). Atendimento ao
                deficiente auditivo ou de fala: 0800 723 0658 (24 horas por dia,
                7 dias por semana, em todo Brasil). Ouvidoria 0800 202
                1264 (24 horas por dia, 7 dias por semana, em todo o Brasil).
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
