import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import { TextField, InputLabel, Divider, Modal, Box } from "@mui/material";
import Chevrolet from "../../assets/images/png/chevolet.png";
import logoMetilife from "../../assets/images/png/logoMetilife.png";
import "./index.css";
import { useNavigate } from "react-router/dist";
import { toast } from "react-toastify";
import Mask from "../../services/mask";
import storage from "../../services/storage";
import Linkedin from "../../assets/images/svg/linkedin.svg";
import Facebook from "../../assets/images/svg/facebook.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Youtube from "../../assets/images/svg/youtube.svg";
import Twitter from "../../assets/images/png/twitter.png";
import { useLoad } from "../../context/Load";

import CloseIcon from "@mui/icons-material/Close";
import VerSenha from "../../Components/VerSenha";

export default function Login() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const [verSenha1, setVerSenha1] = useState(false);
  const [verSenha2, setVerSenha2] = useState(false);
  const [verSenha3, setVerSenha3] = useState(false);

  var meuPlanoId = storage.get("PLANOID");

  const [primeiroAcesso, setPrimeiroAcesso] = useState({
    planoId: meuPlanoId,
    cpf: "",
    email: "",
    telefone: "",
    senha: "",
    confirmarSenha: "",
  });
  const [redefinirSenha, setRedefinirSenha] = useState({
    userId: "",
    token: "",
    senha: "",
    confirmarSenha: "",
  });
  const [logar, setLogar] = useState({
    userName: "",
    password: "",
  });
  const [modalPrimeiroAcesso, setModalPrimeiroAcesso] = useState(false);
  const [modalEsqueciSenha, setModalEsqueciSenha] = useState(false);
  const [modalNaoFoiPossivel, setModalNaoFoiPossivel] = useState(false);
  const [modalRedefinirSenha, setModalRedefinirSenha] = useState(false);
  const [liberarCampos, setLiberarCampos] = useState(false);
  const [recuperarSenha, setRecuperaSenha] = useState("");

  let rotaUrl = window.location.href;

  async function fnRecuperarSenha() {
    var objEnvio = { email: recuperarSenha };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/esqueci-minha-senha",
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success(response.data);
        setModalEsqueciSenha(false);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPrimeiroAcesso() {
    if (!validaEmail(primeiroAcesso.email)) {
      toast.error("E-mail inválido");
      return;
    }

    if (!validaSenha(primeiroAcesso.senha)) {
      toast.error("A senha não atende aos critérios");
      return;
    }
    if (primeiroAcesso.senha !== primeiroAcesso.confirmarSenha) {
      toast.error("A senha e a confirmação de senha não coincidem");
      return;
    }

    var objEnvio = {
      planoPeriodoId: storage.get("PERIODOID"),
      planoId: storage.get("PLANOID"),
      cpf: Mask.unMask(primeiroAcesso.cpf),
      email: primeiroAcesso.email,
      telefone: Mask.unMask(primeiroAcesso.telefone),
      senha: primeiroAcesso.senha,
      confirmarSenha: primeiroAcesso.confirmarSenha,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "participante/primeiro-acesso/salvar",
        objEnvio
      );
      if (response.statusCode == 200) {
        fnLoginPrimeiroAcesso();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnRedefinirSenha() {
    if (!validaSenha(redefinirSenha.senha)) {
      toast.error("A senha não atende aos critérios");
      return;
    }
    if (redefinirSenha.senha !== redefinirSenha.confirmarSenha) {
      toast.error("A senha e a confirmação de senha não coincidem");
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("UserName");

    const token = urlParams.get("token");

    let login = { userName: userName, password: redefinirSenha.senha };
    var objEnvio = {
      userName: userName || "",
      token: token || "",
      senha: redefinirSenha.senha,
      confirmarSenha: redefinirSenha.confirmarSenha,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/confirmar-troca-senha",
        objEnvio
      );
      if (response.statusCode === 200) {
        fnLogin(login);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const validaEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validaSenha = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSpecialChar = (password.match(/[^a-zA-Z0-9]/g) || []).length >= 1;
    return password.length >= minLength && hasLetter && hasSpecialChar;
  };

  const validaParticipanteBase = async () => {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `participante/primeiro-acesso/validar?cpf=${
          modalPrimeiroAcesso
            ? Mask.unMask(primeiroAcesso.cpf)
            : Mask.unMask(logar.userName)
        }`
      );

      if (response.statusCode == 200) {
        if (!modalPrimeiroAcesso) {
          if (response.data.codigo == "fora-da-base") {
            setModalNaoFoiPossivel(true);
          } else {
            fnLogin();
          }
        } else if (
          modalPrimeiroAcesso &&
          response.data.codigo == "dentro-da-base"
        ) {
          setPrimeiroAcesso((prevState) => ({
            ...prevState,
            telefone: response.data.telefone,
            email: response.data.email,
          }));

          setLiberarCampos(true);
        } else if (
          modalPrimeiroAcesso &&
          response.data.codigo == "fora-da-base"
        ) {
          setLiberarCampos(false);
          setModalNaoFoiPossivel(true);
        } else {
          toast.warning(
            "Seu cadastro já foi concluído. Acesse o sistema digitando seu CPF e senha."
          );
        }
      } else {
        toast.error(response.message);
        setLiberarCampos(false);
        setModalNaoFoiPossivel(true);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  };

  async function fnAlteraPlano() {
    var objEnvio = {
      planoId: storage.get("PLANOID"),
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("contrato/plano", objEnvio);
      fnAlteraPeriodo();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnAlteraPeriodo() {
    var objEnvio = {
      planoPeriodoId: storage.get("PERIODOID"),
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("contrato/periodo", objEnvio);
      if (response.statusCode == 200) {
        window.scrollTo(0, 0);
        navigate("/plano-cadastro/dados-titular");
      } else {
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnLogin(objEnvio) {
    objEnvio = objEnvio || {
      userName: Mask.unMask(logar.userName),
      password: logar.password,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("login", objEnvio);
      if (response.statusCode == 200) {
        window.scrollTo(0, 0);
        storage.set("TOKEN", response.data);
        fnAlteraPlano();
        fnCarregarInfoCard();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnLoginPrimeiroAcesso() {
    var objEnvio = {
      userName: Mask.unMask(primeiroAcesso.cpf),
      password: primeiroAcesso.senha,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("login", objEnvio);
      if (response.statusCode == 200) {
        window.scrollTo(0, 0);
        storage.set("TOKEN", response.data);
        fnCarregarInfoCardPrimeiroAcesso();

        storage.get("PERIODOID") == null
          ? storage.get("PLANOID") == null
            ? navigate("/")
            : navigate("/plano/detalhe")
          : navigate("/plano-cadastro/dados-titular");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnCarregarInfoCard() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);
      if (response.statusCode == 200) {
        storage.set("INFOCONTRATO", response.data);
        if (response.data.finalizado === true) {
          storage.set("HABILITAR", false);
          navigate("/cancelamento");
        } else {
          storage.set("HABILITAR", true);
          navigate("/");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnCarregarInfoCardPrimeiroAcesso() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);
      if (response.statusCode == 200) {
        storage.set("INFOCONTRATO", response.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (rotaUrl.includes("redefinir-senha")) {
      setModalRedefinirSenha(true);
    } else {
      setModalRedefinirSenha(false);
    }
  }, [rotaUrl]);

  useEffect(() => {
    if (primeiroAcesso?.cpf?.length != 14) {
      setLiberarCampos(false);
    }
  }, [primeiroAcesso.cpf]);

  return (
    <>
      <div className="login ">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 loginConteudo d-flex flex-column justify-content-center align-items-center">
            <div className="col-6 mt-5">
              <div className="d-flex justify-content-between align-items-center ">
                <img width={"45%"} alt="logoChevrolet" src={Chevrolet} />
                <img width={"45%"} alt="logoChevrolet" src={logoMetilife} />
              </div>
              <div className="my-3 mt-5">
                <TextField
                  id="demo-helper-text-misaligned"
                  label="CPF"
                  className="inputLogin"
                  value={logar.userName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    e.persist();
                    setLogar((prev) => ({
                      ...prev,
                      userName: Mask.cpf(e.target.value),
                    }));
                  }}
                  fullWidth
                  size="small"
                />
              </div>
              <div className="mt-5 position-relative">
                <TextField
                  className="inputLogin"
                  id="demo-helper-text-misaligned"
                  label="Senha"
                  type={!verSenha1 ? "password" : "text"}
                  value={logar.password}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    e.persist();
                    setLogar((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  fullWidth
                  size="small"
                />
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 5,
                    cursor: "pointer",
                  }}
                >
                  <VerSenha setVerSenha={setVerSenha1} verSenha={verSenha1} />
                </div>
              </div>

              <div className="text-end mt-2">
                <span
                  className="pointer esqueciSenha"
                  onClick={() => {
                    setModalEsqueciSenha(true);
                  }}
                >
                  Esqueci a senha
                </span>
              </div>
              <div className="mt-5 d-flex flex-column align-items-center">
                <button
                  className="btn-primary "
                  onClick={validaParticipanteBase}
                >
                  CONTINUAR
                </button>
              </div>
              <div className="mt-4 mb-4 d-flex flex-column align-items-center">
                <button
                  className="btn btn-outline-primary "
                  onClick={() => {
                    setModalPrimeiroAcesso(true);
                  }}
                >
                  PRIMEIRO ACESSO
                </button>
              </div>
            </div>

            <div
              className="text-center  w-100 mb-3"
              style={
                {
                  // marginTop: "50px",
                }
              }
            >
              <hr style={{ color: "#949FAC" }} />
              {/* <span className="textoMetilife">
                2023 General Motors do Brasil. Todos os direitos reservados.
                Proibida a reprodução total ou parcial.
              </span> */}
              <div className="mt-2">
                <img src={Linkedin} alt="icone linkedin" className="me-4" />
                <img src={Facebook} alt="icone facebook" className="me-4" />
                <img src={Instagram} alt="icone instagram" className="me-4" />
                <img src={Twitter} alt="icone instagram" className="me-4" />
                <img src={Youtube} alt="icone youtube" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*///////////////////// PRIMEIRO ACESSO MODAL ///////////////////// */}

      {modalPrimeiroAcesso && (
        <div className="modalExterno">
          <div className="modalInterno ">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModalPrimeiroAcesso(false);
                setPrimeiroAcesso({});
              }}
              style={{ fontSize: 30 }}
            />

            <div>
              <h3 className={liberarCampos ? "" : "mb-5d"}>Primeiro acesso</h3>
              {liberarCampos && (
                <div className="mb-5">
                  <div className="esqueciParagrafo mt-3">
                    Preencha os dados abaixo e crie senha, contendo:
                  </div>
                  <div className="esqueciParagrafo">
                    - No mínimo 8 caracteres;
                  </div>
                  <div className="esqueciParagrafo">- Com letras;</div>
                  <div className="esqueciParagrafo">
                    - Pelo menos 1 caractere especial (!@#$%).
                  </div>
                </div>
              )}
              <div className="my-3">
                <TextField
                  id="demo-helper-text-misaligned"
                  label="CPF"
                  className="inputLogin"
                  value={primeiroAcesso.cpf}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    e.persist();
                    setPrimeiroAcesso((prevState) => ({
                      ...prevState,
                      cpf: Mask.cpf(e.target.value),
                    }));
                  }}
                  fullWidth
                  size="small"
                />
              </div>
              {liberarCampos && (
                <div className="my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="E-mail"
                    className="inputLogin"
                    value={primeiroAcesso.email}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      setPrimeiroAcesso((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
              {liberarCampos && (
                <div className="my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Telefone"
                    className="inputLogin"
                    value={Mask.cel(primeiroAcesso.telefone)}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      setPrimeiroAcesso((prevState) => ({
                        ...prevState,
                        telefone: Mask.cel(e.target.value),
                      }));
                    }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
              {liberarCampos && (
                <div className="position-relative my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Criar Senha"
                    type={!verSenha2 ? "password" : "text"}
                    className="inputLogin"
                    value={primeiroAcesso.senha}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      const newPassword = e.target.value;
                      setPrimeiroAcesso((prevState) => ({
                        ...prevState,
                        senha: newPassword,
                        senhaErro: !validaSenha(newPassword)
                          ? "Formato de senha inválido"
                          : "",
                      }));
                    }}
                    fullWidth
                    size="small"
                    error={Boolean(primeiroAcesso.senhaErro)}
                    helperText={primeiroAcesso.senhaErro}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 5,
                      cursor: "pointer",
                    }}
                  >
                    <VerSenha setVerSenha={setVerSenha2} verSenha={verSenha2} />
                  </div>
                </div>
              )}
              {liberarCampos && (
                <div className="position-relative my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Repetir Senha"
                    className="inputLogin"
                    type={!verSenha3 ? "password" : "text"}
                    value={primeiroAcesso.confirmarSenha}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      const newPasswordConfirmation = e.target.value;
                      setPrimeiroAcesso((prevState) => ({
                        ...prevState,
                        confirmarSenha: newPasswordConfirmation,
                        senhaConfirmacaoErro:
                          newPasswordConfirmation !== primeiroAcesso.senha
                            ? "Senha não confere"
                            : "",
                      }));
                    }}
                    fullWidth
                    size="small"
                    error={Boolean(primeiroAcesso.senhaConfirmacaoErro)}
                    helperText={primeiroAcesso.senhaConfirmacaoErro}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 5,
                      cursor: "pointer",
                    }}
                  >
                    <VerSenha setVerSenha={setVerSenha3} verSenha={verSenha3} />
                  </div>
                </div>
              )}

              {liberarCampos ? (
                <div className="mt-4  d-flex">
                  <button
                    className="btn btn-primary"
                    onClick={fnPrimeiroAcesso}
                  >
                    CRIAR ACESSO
                  </button>
                </div>
              ) : (
                <div className="mt-4  d-flex">
                  <button
                    className={"btn btn-primary"}
                    disabled={primeiroAcesso.cpf?.length != 14}
                    onClick={validaParticipanteBase}
                  >
                    CONTINUAR
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/*///////////////////// ESQUECI SENHA MODAL ///////////////////// */}

      {modalEsqueciSenha && (
        <div className="modalExterno">
          <div className="modalInterno ">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModalEsqueciSenha(false);
                setRecuperaSenha("");
              }}
              style={{ fontSize: 30 }}
            />

            <div className="primeiroAcessoConteudo">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-12  d-flex justify-content-center align-items-center">
                  <div className="col-12">
                    <h3>Esqueci minha senha</h3>
                    <div className="mb-5">
                      <div className="esqueciParagrafo mt-3">
                        Insira seu e-mail cadastrado e enviaremos um link
                      </div>
                      <div className="esqueciParagrafo">
                        para redefinição de senha.
                      </div>
                    </div>
                    <div className="my-3">
                      <TextField
                        id="demo-helper-text-misaligned"
                        label="E-mail"
                        className="inputLogin"
                        value={recuperarSenha}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          e.persist();
                          setRecuperaSenha(e.target.value);
                        }}
                        placeholder="Informe seu e-mail"
                        fullWidth
                        size="small"
                      />
                    </div>

                    <div className="mt-4 d-flex">
                      <button
                        className="btn btn-primary"
                        onClick={fnRecuperarSenha}
                        disabled={!validaEmail(recuperarSenha)}
                      >
                        ENVIAR LINK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*///////////////////// REDEFINIR SENHA MODAL ///////////////////// */}

      {modalRedefinirSenha && (
        <div className="modalExterno">
          <div className="modalInterno ">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModalRedefinirSenha(false);
                setRedefinirSenha({});
                navigate("/login");
              }}
              style={{ fontSize: 30 }}
            />

            <div className="primeiroAcessoConteudo">
              <div className="col-12">
                <h3>Redefinição de senha</h3>
                <div className="mb-5">
                  <div className="esqueciParagrafo mt-3">
                    Cria sua nova senha, contendo
                  </div>
                  <div className="esqueciParagrafo">
                    - No mínimo 8 caracteres;
                  </div>
                  <div className="esqueciParagrafo">- Com letras;</div>
                  <div className="esqueciParagrafo">
                    - Pelo menos 1 caractere especial (!@#$%).
                  </div>
                </div>
                <div className="my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Criar Senha"
                    className="inputLogin"
                    value={redefinirSenha.senha}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      setRedefinirSenha((prevState) => ({
                        ...prevState,
                        senha: e.target.value,
                      }));
                    }}
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="my-3">
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Repetir Senha"
                    className="inputLogin"
                    value={redefinirSenha.confirmarSenha}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      e.persist();
                      setRedefinirSenha((prevState) => ({
                        ...prevState,
                        confirmarSenha: e.target.value,
                      }));
                    }}
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="mt-4 d-flex">
                  <button
                    className="btn btn-primary"
                    onClick={fnRedefinirSenha}
                  >
                    SALVAR SENHA E ACESSAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*///////////////////// NÃO FOI POSSÍVEL MODAL ///////////////////// */}

      {modalNaoFoiPossivel && (
        <div className="modalExterno">
          <div className="modalInterno ">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModalNaoFoiPossivel(false);
              }}
              style={{ fontSize: 30 }}
            />
            <div className="primeiroAcessoConteudo text-center">
              <h3>"NÃO FOI POSSÍVEL PROSSEGUIR COM A CONTRATAÇÃO"</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
