import React, { useEffect, useState } from "react";
import "./index.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useLoad } from "../../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../../services/apiServices";
import Detalhe from "../../../assets/images/svg/detalhe.svg";
import Mask from "../../../services/mask";
import { toast } from "react-toastify";
import { useObjTelas } from "../../../context/ObjEnvio";
import moment from "moment/moment";
import storage from "../../../services/storage";
import PosResumo from "../posResumo";
export default function Resumo() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { objTelas, setObjTelas } = useObjTelas();
  const [mostraModal, setMostraModal] = useState(false);
  const [sexoGenero, setSexoGenero] = useState([]);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([]);
  async function fnCarregarInfoCard() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);
      setInfoCard(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  function fnSexo() {
    ApiServices.apiGet("sexo").then((data) => {
      setSexoGenero(data.data);
    });
  }
  const secoesAcordeao = [
    {
      id: "principais-coberturas",
      titulo: "Principais coberturas",
      conteudo:
        infoCard?.plano?.planoId === "b3a77680-1b21-4e02-a411-c1cd88b5d2a1"
          ? "Você e aqueles que mais ama estarão protegidos com R$ 150.000,00 em caso de morte, e R$ 150.000,00 em caso de Invalidez Permanente por Acidente (IPA) parcial por acidente."
          : infoCard?.plano?.planoId === "2c23a01a-6288-493a-9d65-4b911b12ceee"
          ? "Você e aqueles que mais ama estarão protegidos com R$ 75.000 em caso de morte, R$ 75.000 em caso de Invalidez Permanente por Acidente (IPA) e 4 parcelas de R$ 700 em caso de Perda de renda."
          : "Você e aqueles que mais ama estarão protegidos com R$ 150.000 em caso de morte, R$ 150.000 em caso de Invalidez Permanente por Acidente (IPA) e 4 parcelas de R$ 1.000,00 em caso de perda de renda.",
    },
  ];

  function voltarListagemPlanos() {
    setObjTelas((prev) => ({ ...prev, checkTrocarPlano: true }));

    navigate("/");
  }

  async function fnEnvia() {
    try {
      var objEnvio = {
        bandeiraId: objTelas?.pagamento?.bandeiraId,
        numeroCartao: Mask.unMask(objTelas?.pagamento?.numeroCartao),
        nomeTitular: objTelas?.pagamento?.nomeTitular,
        validade: formatarData(objTelas?.pagamento?.validade),
      };

      let response = await ApiServices.apiPost(
        "contrato/pagamento/confirmar",
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success(response.data);
        window.scrollTo(0, 0);
        setMostraModal(true);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.warn("err", e);
    }
  }
  async function fnListarEstadoCivil() {
    try {
      let response = await ApiServices.apiGet(`estado-civil`);
      if (response.statusCode == 200) {
        setListaEstadoCivil(response.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }
  function formatarData(data) {
    if (data) {
      const partesData = data.split("/");
      if (partesData.length === 2) {
        const mes = partesData[0]?.padStart(2, "0");
        const ano = partesData[1];
        const dia = "01";

        return `20${ano}-${mes}-${dia}`;
      }
    }
    return null;
  }

  const preco = infoCard?.periodo?.custo?.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  useEffect(() => {
    fnCarregarInfoCard();
    fnSexo();
    fnListarEstadoCivil();
  }, []);

  return (
    <>
      <div className="row mt-5">
        <div className="col md-6">
          {/* Detalhes do seguro */}
          <h4 className="bordaTexto">Detalhes do seguro</h4>
          <img
            src={Detalhe}
            alt="detalhePlano"
            className="detalhe"
            style={{ margin: "-8px 0 0 10px" }}
            onClick={voltarListagemPlanos}
          />
          <div>{infoCard?.plano?.nome}</div>
          <div>{preco}/mês e por pessoa</div>
          <div>Início de vigência: {moment().format("DD/MM/YYYY")}</div>
          <div>Meses: {infoCard?.periodo?.validade}</div>
          <br />
          <h4 className="bordaTexto">
            Forma e periodicidade de pagamento do prêmio:
          </h4>
          <div></div>
          <div>Forma de Pagamento: cartão de crédito</div>
          <div>Periodicidade de pagamento: Mensal.</div>
          <br />
          {/* Proteção em caso de: */}
          {/* Proteção em caso de: */}
          {/* Proteção em caso de: */}{" "}
          <h4 className="bordaTexto">Proteção em caso de</h4>
          <div>
            <div className="cadaCardDetCobfertura">
              <div>
                {infoCard?.plano?.planoId ===
                "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" ? (
                  <div>Morte: R$ 150.000,00</div>
                ) : infoCard?.plano?.planoId ===
                  "2c23a01a-6288-493a-9d65-4b911b12ceee" ? (
                  <div>Morte: R$ 75.000</div>
                ) : (
                  <div>Morte: R$ 150.000,00</div>
                )}
              </div>

              {infoCard?.plano?.planoId ===
                "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
                <div>
                  <div>
                    Invalidez Permanente por Acidente (IPA): R$ 150.000,00
                  </div>
                </div>
              )}
              {infoCard?.plano?.planoId ===
                "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                <div>
                  <div>
                    Invalidez Permanente por Acidente (IPA): R$ 75.000,00
                  </div>
                </div>
              )}
              {infoCard?.plano?.planoId ===
                "ff5d327d-30d7-45fe-8170-41c654bd11c1" && (
                <div>
                  <div>
                    Invalidez Permanente por Acidente (IPA): R$ 150.000,00
                  </div>
                </div>
              )}
              {infoCard?.plano?.planoId !==
              "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" ? (
                <div>
                  {infoCard?.plano?.planoId ===
                  "ff5d327d-30d7-45fe-8170-41c654bd11c1" ? (
                    <div>Perda de renda: 4 parcelas de R$ 1.000,00</div>
                  ) : (
                    infoCard?.plano?.planoId ===
                      "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                      <div>Perda de renda: 4 parcelas de R$ 700,00</div>
                    )
                  )}
                </div>
              ) : (
                <div style={{ display: "none" }}></div>
              )}
            </div>
          </div>
          <br />
          {infoCard?.plano?.planoId !==
            "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
            <div>
              <h4 className="bordaTexto">Elegibilidade Perda de Renda</h4>

              <div>
                <b>Desemprego Involuntário: </b>
                na data da rescisão involuntária deverá comprovar um período
                mínimo de 12 (doze) meses consecutivos e ininterruptos de
                trabalho para o atual empregador em regime de CLT
                <br />
                Incapacidade Física Total e Temporária: elegíveis os
                profissionais liberais ou autônomos devidamente comprovados.
                <br />
                Internação Hospitalar por Acidente ou Doença: elegíveis os
                profissionais liberais ou autônomos não comprovados.
              </div>
            </div>
          )}
        </div>
        <div className="col md-6">
          <div>
            <h4 className="bordaTexto">Dados cadastrais</h4>
            <img
              src={Detalhe}
              alt="detalhePlanoDadosCadastrais"
              className="detalhe"
              style={{ margin: "-8px 0 0 10px" }}
              onClick={() => {
                navigate("/plano-cadastro/dados-titular");
              }}
            />
          </div>
          <div>Nome: {storage.get("INFOCONTRATO")?.titular?.nome}</div>
          <div>
            Sexo:{" "}
            {sexoGenero.map((e, i) => {
              if (
                e.sexoId === storage?.get("INFOCONTRATO")?.titular?.sexo?.sexoId
              ) {
                return e.nome;
              }
            })}
          </div>
          <div>
            Estado Civil:{" "}
            {listaEstadoCivil.map((e, i) => {
              if (
                e.estadoCivilId ===
                storage?.get("INFOCONTRATO")?.titular?.estadoCivil
                  ?.estadoCivilId
              ) {
                return e.nome;
              }
            })}
          </div>
          <div>CPF: {Mask.cpf(storage.get("INFOCONTRATO")?.titular?.cpf)}</div>
          <div>E-mail: {storage.get("INFOCONTRATO")?.titular?.email}</div>
          <div>
            Telefone: {Mask.cel(storage.get("INFOCONTRATO")?.titular?.telefone)}
          </div>
          <div>
            Data de nascimento:{" "}
            {moment(
              storage.get("INFOCONTRATO")?.titular?.dataNascimento
            ).format("DD/MM/YYYY")}
          </div>
          <br />
          <h4 className="bordaTexto">Limite de Idade</h4>
          <div>Entre 18 (dezoito) e 70 (setenta) anos</div>
          <br />
          {infoCard?.plano?.planoId !==
            "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
            <div>
              <h4 className="bordaTexto">Carência</h4>
              <div className=" d-flex  flex-column">
                <div>Desemprego Involuntário: 31 dias</div>
                <div>Incapacidade Física Total e Temporária: 31 dias</div>
                <div>Internação Hospitalar por Acidente ou Doença: 31 dias</div>
              </div>
              <br />
              <h4 className="bordaTexto">Franquia</h4>
              <div>Desemprego Involuntário: 31 dias</div>
              <div>Incapacidade Física Total e Temporária: 15 dias</div>
              <div>Internação Hospitalar por Acidente ou Doença: 15 dias</div>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <h4 className="bordaTexto">Cancelamento</h4>
          <br />
          Você poderá desistir da contratação no prazo de 7 (sete) dias corridos
          após a confirmação da compra, exercendo seu direito de reembolso pelo
          mesmo meio utilizado para contratação, sem prejuízos de utilizar
          outros meios disponibilizados pela Operadora.
          <br />
          <br />
          O Seguro pode ser cancelado a qualquer momento, desde que respeitada a
          antecedência mínima de 30 dias do Prêmio a vencer. A solicitação
          deverá ser feita através dos canais de atendimento da MetLife. O
          referido prazo começará a ser contado a partir da data de recebimento
          da solicitação a Seguradora.
          <br />
          <br />
          Central de Atendimento: 3003 Life - 3003 5433 (capitais e grandes
          centros), 0800 MetLife - 0800 638 5433 (demais localidades). SAC -
          Serviço de Apoio ao Cliente Reclamações, Cancelamentos, Sugestões e
          Elogios: 0800 746 3420 (24 horas por dia, 7 dias por semana, em todo o
          Brasil). Atendimento ao deficiente auditivo ou de fala: 0800 723 0658
          (24 horas por dia, 7 dias por semana, em todo o Brasil). Ouvidoria 0800
          202 1264 (24 horas por dia, 7 dias por semana, em todo o Brasil)
        </div>
      </div>
      <div className="row mt-5">
        <div className="d-flex justify-content-center">
          <div className="col-3">
            <button
              className="btn btn-primary mt-5"
              onClick={() => {
                navigate("/plano-cadastro/pagamento");
              }}
            >
              CONTRATAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
