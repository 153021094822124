// ###########################################
// # variaveis de configuração do sistema    #
// ###########################################

////PRODUCAO//////

var globals = {
    REACT_APP_APIMETLIFE: process.env.REACT_APP_APIMETLIFE,
    REACT_APP_ENDERECO: process.env.REACT_APP_ENDERECO,
  };

export default globals;