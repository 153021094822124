import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Home from "../Pages/Home";

import PlanoCadastro from "../Pages/PlanoCadastro";
import Login from "../Pages/Login";
import EsqueciSenha from "../Pages/EsqueciSenha";
import PlanoDetalhe from "../Pages/PlanoDetalhe";
import PrimeiroAcesso from "../Pages/PrimeiroAcesso";
import storage from "../services/storage";
import PosResumo from "../Pages/PlanoCadastro/posResumo";
import Cancelamento from "../Pages/PlanoCadastro/Cancelamento";

const PrivateRoutes = ({ children, redirectTo }) => {
  const autenticado = storage.get("TOKEN") != null;
  // const autenticado = true;
  return autenticado ? (
    <>
      <Header /> <div style={{ marginBottom: 150 }}>{children}</div> <Footer />
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
};
const HomeRoute = ({ children, redirectTo }) => {
  return (
    <>
      <Header /> <div style={{ marginBottom: 150 }}>{children}</div> <Footer />
    </>
  );
};

export default function Rotas() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<HomeRoute><Home /></HomeRoute>}/>
        <Route exact path="/plano/detalhe" element={<HomeRoute><PlanoDetalhe /></HomeRoute>}/>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/redefinir-senha" element={<Login />}></Route>
        <Route exact path="/primeiro-acesso" element={<PrimeiroAcesso />}></Route>
        <Route exact path="/esqueci-senha" element={<EsqueciSenha />}></Route>
        <Route exact path="/plano-cadastro/:tela" element={<PrivateRoutes><PlanoCadastro /> </PrivateRoutes>}/>
        <Route exact path="/cancelamento" element={<PrivateRoutes><Cancelamento /> </PrivateRoutes>}/>
        {/* <Route exact path="/consulta/:tela" element={ <PrivateRoutes>  <MultiStepProgressBar /> </PrivateRoutes>}/> */}
      </Routes>
    </div>
  );
}
