import React from "react";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";

export default function VerSenha({
  tamanho = 30,
  verSenha,
  setVerSenha,
  top = -35,
}) {
  return (
    <>
      <div
        onClick={() => {
          setVerSenha(!verSenha);
        }}
      >
        {verSenha ? (
          <IoIosEye style={{ fontSize: tamanho }} />
        ) : (
          <IoIosEyeOff style={{ fontSize: tamanho }} />
        )}
      </div>
    </>
  );
}
