import React, { useEffect, useState } from "react";
import "./index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useLoad } from "../../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../../services/apiServices";
import { useObjTelas } from "../../../context/ObjEnvio";

import Icone1 from "../../../assets/images/svg/card1.svg";
import Icone2 from "../../../assets/images/svg/card2.svg";
import Icone3 from "../../../assets/images/svg/card3.svg";
import storage from "../../../services/storage";
import { toast } from "react-toastify";

export default function Cards() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const navigate = useNavigate();
  const [selectedPlanoId, setSelectedPlanoId] = useState(null);
  const planosId = [
    "2c23a01a-6288-493a-9d65-4b911b12ceee",
    "b3a77680-1b21-4e02-a411-c1cd88b5d2a1",
    "ff5d327d-30d7-45fe-8170-41c654bd11c1",
  ];
  const { objTelas, setObjTelas } = useObjTelas();
  var InfoContrato = storage.get("INFOCONTRATO");
  var Habilitar = storage.get("HABILITAR");
  var userLogado = storage.get("TOKEN") != null;

  async function fnCarregarInfoCard() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("plano");
      setInfoCard(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnAlteraPlano(idPlano) {
    storage.set("PLANOID", idPlano);
    var objEnvio = {
      planoId: idPlano,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("contrato/plano", objEnvio);
      if (response.statusCode == 200) {
        window.scrollTo(0, 0);
        if (InfoContrato?.finalizado || userLogado) {
          navigate("/plano/detalhe");
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
      // toast.error("Faça o seu login");
      // navigate("/login");
      navigate("/plano/detalhe");
    } finally {
      setLoad(false);
    }
  }

  function fnDetalhePlano() {
    if (userLogado) {
      return fnAlteraPlano();
    }

    if (!InfoContrato?.finalizado || !userLogado) {
      window.scrollTo(0, 0);
      navigate("/login");
    }
  }

  const fnMeuPlanoId = (planoId) => {
    setSelectedPlanoId(planoId);
    setObjTelas((prevState) => ({
      ...prevState,
      planoId: planoId,
    }));
    storage.set("PLANOID", planoId);
  };

  const primeiroPlano = infoCard.find((plan) => plan.planoId === planosId[0]);
  const segundoPlano = infoCard.find((plan) => plan.planoId === planosId[1]);
  const terceiroPlano = infoCard.find((plan) => plan.planoId === planosId[2]);

  const planoSelecionadoAnterior = () => {
    if (
      InfoContrato?.plano?.planoId &&
      planosId.includes(InfoContrato?.plano.planoId)
    ) {
      setSelectedPlanoId(InfoContrato?.plano.planoId);
    }
  };

  useEffect(() => {
    fnCarregarInfoCard();
    planoSelecionadoAnterior(); // Check and set initial selected plan
  }, []);

  return (
    <div className="container" style={{ marginTop: -45 }}>
      {/* TODOS OS CARDS */}
      {/* TODOS OS CARDS */}
      {/* TODOS OS CARDS */}
      <div className="d-flex justify-content-center justify-content-lg-between flex-wrap">
        {/* CARD 1 */}
        {/* CARD 1 */}
        {/* CARD 1 */}
        <div className="cadaCardHome flex-column mt-4 mb-2 radio-container">
          {/* <img width={100} src={Icone1} alt="icone" /> */}
          <h5 className="tituloCardHome">{infoCard[1]?.nome}</h5>
          <b className="subtituloCardHome">Proteção em caso de:</b>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            <div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="nomeCardHome">Morte</div>
                <div className="precoCardHome">R$ 150.000</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="nomeCardHome">
                  Invalidez Permanente por Acidente (IPA)
                </div>
                <div className="precoCardHome">R$ 150.000</div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <div>A partir de </div>
                <span className="precoCardHomeMensalidade">
                  {" "}
                  {infoCard[1]?.periodos[0]?.custo?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
                <div>* por mês</div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    fnAlteraPlano("b3a77680-1b21-4e02-a411-c1cd88b5d2a1");
                  }}
                >
                  Eu quero
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* CARD 2 */}
        {/* CARD 2 */}
        {/* CARD 2 */}

        <div
          className="cadaCardHome flex-column 4 mb-2 position-relative "
          style={{
            border: "solid 10px #0078d9",
            marginTop: 15,
          }}
        >
          <div className="sugeridoParaVoce">Sugerido para você</div>
          {/* <img width={80} src={Icone2} alt="icone" /> */}
          <h5 className="tituloCardHome ">{infoCard[2]?.nome}</h5>
          <b className="subtituloCardHome">Proteção em caso de:</b>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            <div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="nomeCardHome">Morte</div>
                <div className="precoCardHome">R$ 150.000</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="nomeCardHome">
                  Invalidez Permanente por Acidente (IPA)
                </div>
                <div className="precoCardHome">R$ 150.000</div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-5">
                <div className="nomeCardHome">Perda de Renda</div>
                <div className="precoCardHome">
                  {/* <div className="d-flex justify-content-end">4 parcelas</div> */}
                  <div className="d-flex justify-content-end">
                    4 parcelas de
                  </div>
                  <div className="d-flex justify-content-end">R$ 1.000</div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <div>A partir de </div>
                <span className="precoCardHomeMensalidade">
                  {infoCard[2]?.periodos[0]?.custo?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
                <div>* por mês</div>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: -20 }}
              >
                <button
                  className="btn btn-primary"
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    fnAlteraPlano("ff5d327d-30d7-45fe-8170-41c654bd11c1");
                  }}
                >
                  Eu quero
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* CARD 3 */}
        {/* CARD 3 */}
        {/* CARD 3 */}

        <div className="cadaCardHome flex-column mt-4 mb-2 radio-container ">
          {/* <img width={100} src={Icone3} alt="icone" /> */}
          <h5 className="tituloCardHome">{infoCard[0]?.nome}</h5>
          <b className="subtituloCardHome">Proteção em caso de:</b>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            <div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="nomeCardHome">Morte</div>
                <div className="precoCardHome">R$ 75.000</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="nomeCardHome">
                  Invalidez Permanente por Acidente (IPA)
                </div>
                <div className="precoCardHome">R$ 75.000</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="nomeCardHome">Perda de Renda</div>
                <div className="precoCardHome">
                  {/* <div className="d-flex justify-content-end">4 parcelas</div> */}
                  <div className="d-flex justify-content-end">
                    4 parcelas de
                  </div>
                  <div className="d-flex justify-content-end">R$ 700</div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <div>A partir de </div>
                <span className="precoCardHomeMensalidade">
                  {infoCard[0]?.periodos[0]?.custo?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
                <div>* por mês</div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    fnAlteraPlano("2c23a01a-6288-493a-9d65-4b911b12ceee");
                  }}
                >
                  Eu quero
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
