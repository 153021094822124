import React, { useEffect, useState } from "react";

import "./index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DadosTitular from "./DadosTitular";
import Pagamento from "./Pagamento";
import Resumo from "./Resumo";
import { useObjTelas } from "../../context/ObjEnvio";
export default function PlanoCadastro() {
  const navigate = useNavigate();
  const [fecharModal, setFecharModal] = useState(false);
  const [aba, setAba] = useState(0);

  const pathname = () => {
    return window.location.pathname;
  };
  useEffect(() => {
    pathname();
    // navigate('/plano-cadastro/dados-titular')
  }, [navigate]);

  const location = useLocation();

  const { objTelas, setObjTelas } = useObjTelas();

  return (
    <>
      <div className="container">
       
        <div className="mt-5 mb-5" style={{marginLeft:'33px'}}>
          <h2 className="bordaTexto">Cadastro</h2>
        </div>

        <div className="row dadosTitularCard">
          <div className="">
          <div className="mt-5 d-flex flex-column align-items-center flex-lg-row ">
            <Link
              onClick={() => {
                setFecharModal(true);
              }}
       
              className={
                "btnCadastroTitular " +
                (pathname().includes("/plano-cadastro/dados-titular")
                  ? "btnCadastroAtivado"
                  : "")
              }
            >
              <span>Dados do titular</span>
            </Link>
            <Link
              onClick={() => {
                setFecharModal(true);
              }}
              to={"/plano-cadastro/resumo"}
              className={
                "btnCadastroResumo  " +
                (pathname().includes("/plano-cadastro/resumo")
                  ? "btnCadastroAtivado"
                  : "")
              }
            >
              <span>Resumo</span>
            </Link>
            <Link
              onClick={() => {
                setFecharModal(true);
              }}
              // to={"/plano-cadastro/pagamento"}
              className={
                "btnCadastro  " +
                (pathname().includes("/plano-cadastro/pagamento")
                  ? "btnCadastroAtivado"
                  : "")
              }
            >
              <span>Formas de pagamento</span>
            </Link>
            
          </div>
          </div>
          {pathname().includes("dados-titular") && <DadosTitular />}
          {pathname().includes("resumo") && <Resumo />}
          {pathname().includes("pagamento") && <Pagamento />}
        </div>
      </div>
    </>
  );
}
