import { toast } from "react-toastify";
import Storage from "./storage";
import globals from "../globals";

function getToken() {
  let userCredencial = Storage.get("TOKEN");
  // let userCredencial = Storage.get("USUARIO")?.token;
  return userCredencial;
}

const ApiServices = {
  apiLogin: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiLoginBase: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Accept: "*/*",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },
  apiPostTokenBearer: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },

  apiGetTokenBearer: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },

  apiGetWithToken: (parameter = undefined, token = undefined) => {
    if (parameter && token) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },

  apiGet: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },

  apiPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiGetEndereco: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${globals.REACT_APP_ENDERECO}${parameter}`, {
        method: "GET",
      }).then((res) => res.json());
    } else {
    }
  },
  // apiGetEndereco: (parameter = undefined) => {
  //   if (parameter) {
  //     //alert('sem captcha');
  //     return fetch(`${ENDERECO}${parameter}`, {
  //       method: "GET",
  //     }).then((res) => {
  //       if (res.status !== 200) {
  //         return toast.error(res.Message);
  //       }
  //       return res.json();
  //     }).catch((error) => {
  //       toast.error(error.Message);
  //     });
  //   }
  // },

  apiPut: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
      // .then((res) => res);
    } else {
    }
  },
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  apiPostWithToken: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            captcha: captcha,
            Authorization: `Bearer ${getToken()}`,
            //'key':RECAPTCHAKEY
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      }
    } else {
    }
  },

  apiPostWithTokenNotaFiscal: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "multipart/form-data",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },

  apiPatch: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiPutReturnJson: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiDel: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res);
    } else {
    }
  },

  apiPostWithTokenPremiacao: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiGetWithIdAndToken: (parameter = undefined, id = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },
};

export default ApiServices;
