import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import { TextField, InputLabel, Divider } from "@mui/material";

import LogoMetilife from "../../assets/images/png/logoMetilife.png";
import "./index.css";
import { useNavigate } from "react-router/dist";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";

export default function EsqueciSenha() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();

  // const [recuperaSenha, setRecuperaSenha] = useState("");
  const [recuperarSenha, setRecuperaSenha] = useState("");

  async function fnRecuperarSenha() {
    var objEnvio = { email: recuperarSenha };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/esqueci-minha-senha",
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success(response.data);
        navigate('/');
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
<div className="login">
  <div className="row justify-content-center align-items-center">
    <div className="col-md-6 loginConteudo d-flex justify-content-center align-items-center loginMobile">
      <div className="col-8">
        <div className="my-3">
          <div>
            <img className="logoMetilife" src={LogoMetilife} alt="LogoMetilife" />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <h2 className="mt-5 mb-3">Esqueci minha senha</h2>
          <p className="text-center esqueciParagrafo mt-3">
            Digite o CPF cadastrado no portal, será enviado um e-mail com a nova senha.
          </p>
        </div>
        <div className="my-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="E-mail"
            className="inputLogin"
            value={recuperarSenha}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              e.persist();
              setRecuperaSenha(e.target.value);
            }}
            fullWidth
            size="small"
          />
        </div>

        <div className="mt-4 d-flex">
          <button className="botaoDetalhe" onClick={fnRecuperarSenha}>
            Enviar
          </button>
          <button
            className="botaoDetalheSecundario ms-3"
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  
  );
}
