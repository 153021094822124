import React, { useEffect, useState } from "react";
import "./index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useLoad } from "../../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../../services/apiServices";
import { Divider } from "@mui/material";
import storage from "../../../services/storage";
import imagemTestePng from "../../../assets/images/png/teste.png";
import { useObjTelas } from "../../../context/ObjEnvio";
import Icone1 from "../../../assets/images/svg/card1.svg";
import Icone2 from "../../../assets/images/svg/card2.svg";
import Icone3 from "../../../assets/images/svg/card3.svg";
import { toast } from "react-toastify";

export default function Cards() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const [contratoMeu, setContatoMeu] = useState([]);
  const navigate = useNavigate();
  const { objTelas, setObjTelas } = useObjTelas();
  const [periodoSelecionado, setPeriodoSelecionado] = useState("");
  const [contrato, setContrato] = useState([]);
  var Habilitar = storage.get("HABILITAR");
  var userLogado = storage.get("TOKEN") != null;

  async function fnCarregarInfoCard() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);
      if (response.statusCode == 200) {
        setContatoMeu(response.data);
        if (response.data?.periodo?.planoPeriodoId) {
          setPeriodoSelecionado(response.data.periodo);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnDetPlanoNaoLogado() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("plano");
      response.data.map((e, i) => {
        if (e.planoId === storage.get("PLANOID")) {
          setInfoCard(e);
        }
      });
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnDetPlano() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `plano/${contratoMeu?.plano?.planoId}`
      );
      setInfoCard(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnAlteraPeriodo(planoId) {
    setObjTelas((prev) => ({ ...prev, planoId: planoId }));
    var objEnvio = {
      planoPeriodoId: planoId,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost("contrato/periodo", objEnvio);
      if (response.statusCode == 200) {
        window.scrollTo(0, 0);
        navigate("/plano-cadastro/dados-titular");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnContratoMeu() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("contrato/meu");
      setContrato(response.data);
      storage.set("PLANOID", response.data.plano.planoId);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnDetPlanoNaoLogado();

    fnCarregarInfoCard();
    fnContratoMeu();
  }, []);

  useEffect(() => {
    if (contratoMeu?.plano?.planoId) {
      fnDetPlano();
    }
  }, [contratoMeu?.plano?.planoId]);

  return (
    <div className="container">
      {/* Detalhes do seguro */}
      <h2 className="bordaTexto mt-3">Detalhes do seguro</h2>
      <h5 className="tituloCardDet">{infoCard?.nome}</h5>
      {/* Proteção em caso de: */}
      <div className="row mb-4">
        <div className="col md-6">
          {" "}
          <h2 className="bordaTexto">Proteção em caso de:</h2>
          <div>
            <div className="cadaCardDetCobertura d-flex justify-content-center">
              <div className="infoCardCobertura">
                {storage.get("PLANOID") ===
                  "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
                  <span>
                    Morte: <strong>R$ 150.000,00</strong>
                  </span>
                )}

                {storage.get("PLANOID") ===
                  "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                  <span>
                    Morte: <strong>R$ 75.000</strong>
                  </span>
                )}
                {storage.get("PLANOID") ===
                  "ff5d327d-30d7-45fe-8170-41c654bd11c1" && (
                  <span>
                    Morte: <strong>R$ 150.000,00</strong>
                  </span>
                )}
              </div>
            </div>
            <div className="cadaCardDetCobertura  d-flex justify-content-center">
              {storage.get("PLANOID") ===
                "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
                <div className="infoCardCobertura">
                  <span>
                    Invalidez Permanente por Acidente (IPA):{" "}
                    <strong>R$ 150.000,00</strong>
                  </span>
                </div>
              )}
              {storage.get("PLANOID") ===
                "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                <div className="infoCardCobertura">
                  <span>
                    Invalidez Permanente por Acidente (IPA):{" "}
                    <strong>R$ 75.000,00</strong>
                  </span>
                </div>
              )}
              {storage.get("PLANOID") ===
                "ff5d327d-30d7-45fe-8170-41c654bd11c1" && (
                <div className="infoCardCobertura">
                  <span>
                    Invalidez Permanente por Acidente (IPA):{" "}
                    <strong>R$ 150.000,00</strong>
                  </span>
                </div>
              )}
            </div>
            {storage.get("PLANOID") !==
            "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" ? (
              <div className="cadaCardDetCobertura  d-flex justify-content-center">
                <div className="infoCardCobertura">
                  {storage.get("PLANOID") ===
                  "ff5d327d-30d7-45fe-8170-41c654bd11c1" ? (
                    <span>
                      Perda de renda:<strong> 4 parcelas de R$ 1.000,00</strong>
                    </span>
                  ) : (
                    storage.get("PLANOID") ===
                      "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                      <span>
                        Perda de renda:<strong> 4 parcelas de R$ 700,00</strong>
                      </span>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div style={{ display: "none" }}></div>
            )}
          </div>
          {storage.get("PLANOID") !==
            "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
            <div>
              <h2 className="bordaTexto">Elegibilidade Perda de Renda:</h2>
              <div className="cadaCardDetCobertura d-flex justify-content-center">
                <div className="infoCardCobertura">
                  <div>
                    <b>Desemprego Involuntário: </b>
                    na data da rescisão involuntária deverá comprovar um período
                    mínimo de 12 (doze) meses consecutivos e ininterruptos de
                    trabalho para o atual empregador em regime de CLT.
                    <br />
                    <b>Incapacidade Física Total e Temporária: </b>
                    elegíveis os profissionais liberais ou autônomos devidamente
                    comprovados.
                    <br />
                    <b>Internação Hospitalar por Acidente ou Doença: </b>
                    elegíveis os profissionais liberais ou autônomos não
                    comprovados.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col md-6 d-flex justify-content-lg-end justify-content-start">
          <div>
            <h2 className="bordaTexto">Limite de Idade:</h2>
            <div className="cadaCardDetCobertura d-flex justify-content-center">
              <div className="infoCardCobertura">
                <span>Entre 18 (dezoito) e 70 (setenta) anos</span>
              </div>
            </div>

            {storage.get("PLANOID") !==
              "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
              <div>
                <h2 className="bordaTexto">Carência</h2>
                <div className="cadaCardDetCobertura d-flex justify-content-center">
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>Desemprego Involuntário</div>
                    <b>31 dias</b>
                  </div>
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>
                      Incapacidade Física Total e Temporária
                    </div>
                    <b>31 dias</b>
                  </div>
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>
                      Internação Hospitalar por Acidente ou Doença{" "}
                    </div>
                    <b>31 dias</b>
                  </div>
                </div>
                <h2 className="bordaTexto">Franquia</h2>
                <div className="cadaCardDetCobertura d-flex justify-content-center">
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>Desemprego Involuntário</div>
                    <b>31 dias</b>
                  </div>
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>
                      Incapacidade Física Total e Temporária
                    </div>
                    <b>15 dias</b>
                  </div>
                  <div className="infoCardCarencia">
                    <div style={{ maxWidth: 250 }}>
                      Internação Hospitalar por Acidente ou Doença
                    </div>
                    <b>15 dias</b>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Prazo de Proteção */}
      <div className="row mb-4">
        <h2 className="bordaTexto">Prazo de Proteção:</h2>
        <div className="d-flex justify-content-start justify-content-lg-between flex-wrap">
          {Habilitar === false ? (
            <div className=" ">
              {infoCard?.periodos
                ?.filter(
                  (e) => e.planoPeriodoId === periodoSelecionado.planoPeriodoId
                )
                .map((e) => (
                  <div className="cadaCardDetPeriodo d-flex justify-content-center">
                    <div className="infoperiodo">
                      <div>
                        <span>{e.validade} meses</span>
                      </div>
                      <div>
                        <span>
                          <strong> R$ {e.custo}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            infoCard?.periodos?.map((e, i) => (
              <div
                className="cadaCardDetPeriodo d-flex justify-content-center"
                key={i}
              >
                <div className="divProtecao">
                  {i + 1 + " ANO" + (i > 0 ? "S" : "") + " de proteção"}
                </div>
                <div className="infoperiodo mt-3">
                  <div>Valor mensal:</div>
                  <span>
                    {e.validade} meses de{" "}
                    <b>
                      {e.custo.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </b>
                  </span>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      storage.set("PERIODOID", e.planoPeriodoId);
                      storage.get("TOKEN")
                        ? fnAlteraPeriodo(e.planoPeriodoId)
                        : navigate("/login");
                    }}
                  >
                    Contratar
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {contrato?.contratoEtapa?.contratoEtapaId ==
        "c2d9ca1a-ccfb-4c13-a42c-9a8add7b6430" && (
        <div className="row justify-content-center">
          <div className="col-3">
            <button
              className="btn btn-danger btn-lg"
              onClick={() => {
                navigate("/cancelamento");
              }}
            >
              CANCELAR PLANO
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
