import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import { TextField, InputLabel, Divider } from "@mui/material";

import LogoMetilife from "../../assets/images/png/logoMetilife.png";
import "./index.css";
import { useNavigate } from "react-router/dist";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import Mask from "../../services/mask";

export default function PrimeiroAcesso() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const [primeiroAcesso, setPrimeiroAcesso] = useState({
    login: "",
    email: "",
    telefone: "",
    senha: "",
    confirmarSenha: "",
  });

  async function fnPrimeiroAcesso() {
    if (!validaEmail(primeiroAcesso.email)) {
      toast.error("E-mail inválido");
      return;
    }

    if (!validaSenha(primeiroAcesso.senha)) {
      toast.error("A senha não atende aos critérios");
      return;
    }
    if (primeiroAcesso.senha !== primeiroAcesso.confirmarSenha) {
      toast.error("A senha e a confirmação de senha não coincidem");
      return;
    }

    navigate("/plano/detalhe");
  }

  const validaEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validaSenha = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSpecialChar = /[!@#$%]/.test(password);

    return password.length >= minLength && hasLetter && hasSpecialChar;
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6 loginConteudo d-flex justify-content-center align-items-center loginMobile">
          <div className="col-8">
            <div className="d-flex flex-column align-items-center">
              <p className="text-center esqueciParagrafo mt-3">
                Primeiro acesso. Digite seu CPF, que será o login. Preencha os
                dados abaixo e crie senha, contendo: - No mínimo 8 caracteres; -
                Com letras; - Pelo menos 1 caractere especial (!@#$%).
              </p>
            </div>
            <div className="my-3">
              <TextField
                id="demo-helper-text-misaligned"
                label="CPF"
                className="inputLogin"
                value={primeiroAcesso.login}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.persist();
                  setPrimeiroAcesso((prevState) => ({
                    ...prevState,
                    login: Mask.cpf(e.target.value),
                  }));
                }}
                fullWidth
                size="small"
              />
            </div>
            <div className="my-3">
              <TextField
                id="demo-helper-text-misaligned"
                label="E-mail"
                className="inputLogin"
                value={primeiroAcesso.email}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.persist();
                  setPrimeiroAcesso((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
                fullWidth
                size="small"
              />
            </div>
            <div className="my-3">
              <TextField
                id="demo-helper-text-misaligned"
                label="Telefone"
                className="inputLogin"
                value={Mask.cel(primeiroAcesso.telefone)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.persist();
                  setPrimeiroAcesso((prevState) => ({
                    ...prevState,
                    telefone: Mask.cel(e.target.value),
                  }));
                }}
                fullWidth
                size="small"
              />
            </div>
            <div className="my-3">
              <TextField
                id="demo-helper-text-misaligned"
                label="Criar Senha"
                className="inputLogin"
                value={primeiroAcesso.senha}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.persist();
                  setPrimeiroAcesso((prevState) => ({
                    ...prevState,
                    senha: e.target.value,
                  }));
                }}
                fullWidth
                size="small"
              />
            </div>
            <div className="my-3">
              <TextField
                id="demo-helper-text-misaligned"
                label="Repetir Senha"
                className="inputLogin"
                value={primeiroAcesso.confirmarSenha}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.persist();
                  setPrimeiroAcesso((prevState) => ({
                    ...prevState,
                    confirmarSenha: e.target.value,
                  }));
                }}
                fullWidth
                size="small"
              />
            </div>

            <div className="mt-4 d-flex">
              <button className="botaoDetalhe" onClick={fnPrimeiroAcesso}>
                Criar Acesso
              </button>
            </div>
            <div className="mt-4 d-flex">
              <button
                className="botaoDetalheSecundario "
                onClick={() => {
                  navigate(-1);
                }}
              >
                VOLTAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
