import React, { createContext, useContext, useState } from "react";

export const UserContextDefault = {
  objTelas: [],
  setObjTelas: undefined,
};

export const ObjTelasContext = createContext(UserContextDefault);

export const ObjTelasProvider = (props) => {
  const [objTelas, setObjTelas] = useState({
    titular: {
      nome: "",
      sexo: {
        sexoId: "",
      },
      email: "",
      cpf: "",
      telefone: "",
      dataNascimento: "",
        cep: "",
        endereco: "",
        bairro: "",
        numero: "",
        complemento: "",
        uf: {
          ufId: "",
        },
        cidade: {
          cidadeId: "",
        },
    },
    beneficiarios: [],
    pagamento: {
      numeroCartao: "",
      nomeTitular: "",
      validade: "",
      bandeiraId: "",
    },
  });

  return (
    <ObjTelasContext.Provider value={{ objTelas, setObjTelas }}>
      {props.children}
    </ObjTelasContext.Provider>
  );
};

export const useObjTelas = () => {
  const context = useContext(ObjTelasContext);
  const { objTelas, setObjTelas } = context;
  return { objTelas, setObjTelas };
};
