import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import Loading from "./Components/Loading";
import Mensagem from "./Components/Mensagem";
import {LoadProvider} from "./context/Load"; 
import "./index.css";
import Rotas from "./Routes";
import "./styles/global.css";
import { ObjTelasProvider } from "./context/ObjEnvio";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <LoadProvider>
      <ObjTelasProvider>
        <BrowserRouter>
          <Loading />
          <Mensagem />
          <Rotas />
        </BrowserRouter>
      </ObjTelasProvider>
    </LoadProvider>
  </>
);
