import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import LogoChevrolet from "../../assets/images/png/logo_chevrolet.png";
import logoMetilife from "../../assets/images/png/logoMetilife.png";

import Setinha from "../../assets/images/svg/setinha.svg";
import storage from "../../services/storage";
import { useObjTelas } from "../../context/ObjEnvio";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";

export default function Header() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [fecharModal, setFecharModal] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const { objTelas, setObjTelas } = useObjTelas();
  const [fecharPopUp, setFecharPopUp] = useState(false);
  const [contrato, setContrato] = useState([]);
  const pathname = () => {
    return window.location.pathname;
  };

  var token = storage.get("TOKEN") != null;

  async function fnRecuperarSenha() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("eu");
      setInfoUser(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnContratoMeu() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("contrato/meu");
      setContrato(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    pathname();
    setFecharModal(false);
  }, [navigate]);

  useEffect(() => {
    fnRecuperarSenha();
    fnContratoMeu();
  }, []);

  return (
    <div className="container">
      <div className="row py-3">
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <img
              className="me-5"
              width={180}
              src={LogoChevrolet}
              alt="logoChevrolet"
            />
            <img width={180} src={logoMetilife} alt="logoChevrolet" />
          </div>

          <div>
            {pathname().includes("/plano-cadastro/dados-titular") ||
            pathname().includes("/plano-cadastro/pagamento") ||
            pathname().includes("/plano/detalhe") ||
            pathname().includes("/plano-cadastro/ ") ||
            token ? (
              <div
                className="d-flex align-items-center"
                onClick={() => setFecharPopUp(!fecharPopUp)}
              >
                {infoUser?.nome != null && (
                  <span style={{ marginRight: "15px" }}>
                    Olá, <b>{infoUser?.nome}</b>
                  </span>
                )}

                <div className="me-3">
                  <svg
                    fill="#000000"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="796 796 200 200"
                    enableBackground="new 796 796 200 200"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />

                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path d="M896,796c-55.14,0-99.999,44.86-99.999,100c0,55.141,44.859,100,99.999,100c55.141,0,99.999-44.859,99.999-100 C995.999,840.86,951.141,796,896,796z M896.639,827.425c20.538,0,37.189,19.66,37.189,43.921c0,24.257-16.651,43.924-37.189,43.924 s-37.187-19.667-37.187-43.924C859.452,847.085,876.101,827.425,896.639,827.425z M896,983.86 c-24.692,0-47.038-10.239-63.016-26.695c-2.266-2.335-2.984-5.775-1.84-8.82c5.47-14.556,15.718-26.762,28.817-34.761 c2.828-1.728,6.449-1.393,8.91,0.828c7.706,6.958,17.316,11.114,27.767,11.114c10.249,0,19.69-4.001,27.318-10.719 c2.488-2.191,6.128-2.479,8.932-0.711c12.697,8.004,22.618,20.005,27.967,34.253c1.144,3.047,0.425,6.482-1.842,8.817 C943.037,973.621,920.691,983.86,896,983.86z" />{" "}
                    </g>
                  </svg>
                </div>
                <div className="position-relative mb-1">
                  <img
                    src={Setinha}
                    alt="iconeSetinha"
                    style={{ rotate: !fecharPopUp ? "0deg" : "180deg" }}
                  />
                  {fecharPopUp && (
                    <div className="popup flex-column">
                      <span
                        onClick={() => {
                          navigate("/login");
                          localStorage.clear();
                          sessionStorage.clear();
                          setFecharPopUp(false);
                        }}
                      >
                        SAIR
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <button
                className="btn btn-outline-primary d-flex align-items-center justify-content-center"
                style={{ width: 150, height: 30 }}
                onClick={() => navigate("/login")}
              >
                Entrar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
